<template>
  <app>
    <main-page
      v-if="pageData && site"
      :page="pageData"
      :site="site"
      :locale="ctx?.locale"
      :is-mobile="isMobile"
      :num-critical-content-sections="1"
      :custom-components="{
        TrustQuoteSection,
        LeadForm,
        CompanyForm
      }"
      :fragment-custom-query="fragmentCustomQuery"
    />
  </app>
</template>

<script setup lang="ts">
  import { type CustomData, MainPage } from '@ocp-zmarta/content-sdk'
  import { getServerState, useSSRContext } from '@ocp-zmarta/zsc-plugin-vite-ssr/vue'
  import { computed, defineAsyncComponent } from 'vue'
  import companyFormData from '../components/custom/company/company-form-data'
  import leadFormData from '../components/custom/lead/lead-form-data'
  import trustQuoteSectionData from '../components/custom/trust/trust-quote-section-data'
  import app from '../components/layout/app.vue'
  import useFormattedPageData from '../composables/use-formatted-page-data'

  const TrustQuoteSection = defineAsyncComponent(async () => await import('../components/custom/trust/trust-quote-section.vue'))
  const LeadForm = defineAsyncComponent(async () => await import('../components/custom/lead/lead-form.vue'))
  const CompanyForm = defineAsyncComponent(async () => await import('../components/custom/company/company-form.vue'))

  const ctx = import.meta.env.SSR
    ? useSSRContext()
    : ({
      brand: getServerState('brand'),
      market: getServerState('market'),
      locale: getServerState('locale'),
      device: getServerState('device'),
      userId: getServerState('userId'),
      userIp: getServerState('userIp')
    }) as unknown as NonNullable<ReturnType<typeof useSSRContext>>

  const customData = [] as CustomData[]

  const trustQuoteSectionDataLocale = trustQuoteSectionData[ctx?.locale as keyof typeof trustQuoteSectionData]
  if (trustQuoteSectionDataLocale) customData.push(trustQuoteSectionDataLocale)

  const leadFormDataLocale = leadFormData[ctx?.locale as keyof typeof leadFormData]
  if (leadFormDataLocale) customData.push(leadFormDataLocale)

  const companyFormDataLocale = companyFormData[ctx?.locale as keyof typeof companyFormData]
  if (companyFormDataLocale) customData.push(companyFormDataLocale)

  const isMobile = computed(() => ctx?.device === 'mobile')
  const { pageData, site, fragmentCustomQuery } = useFormattedPageData({ ctx, customData })
</script>
